<template>
  <div class="">
    <div class="lable">企业名称<i>*</i></div>
    <PaInput
      class="myInputType"
      v-model="from.Name"
      placeholder="请输入企业名称"
      is_required_text="请输入企业名称"
      successContent="企业名称将用于注册账号名称，请填写完整信息。"
      :submitType="submitType"
      :is_required="true"
    />
    <div class="lable">公司地址<i>*</i></div>
    <div class="mySelectType">
      <PaSelect
        class="myInputType"
        v-model="from.province"
        :is_required="true"
        :submitType="submitType"
        :option="province"
      />
      <PaSelect
        class="myInputType"
        v-model="from.city"
        :is_required="true"
        :submitType="submitType"
        :option="city"
      />
    </div>
    <PaInput
      class="myInputType"
      v-model="from.DetailedAddress"
      placeholder="请输入详细地址"
      is_required_text="请输入详细地址"
      :submitType="submitType"
      :is_required="true"
    />
    <div class="lable">是否从事过跨境电商业务<i>*</i></div>
    <PaSelect
      class="myInputType"
      v-model="from.isPast"
      :is_required="true"
      :submitType="submitType"
      successContent="参照您的经验为您匹配合适的专业人士提供服务"
      :option="isPastList"
    />
    <div class="lable">单日营业额<i>*</i></div>
    <PaSelect
      class="myInputType"
      v-model="from.DailyTurnover"
      :is_required="true"
      :submitType="submitType"
      successContent="选择商店目前的单日营业额，或者您建站完成之后的计划单日营业额。"
      :option="DailyTurnoverList"
      :isTop="true"
    />
    <div class="lable">商品类型<i>*</i></div>
    <PaMCSelect
      class="PaSelect_size"
      v-model="from.CommodityType"
      :is_required="true"
      :submitType="submitType"
      successContent="选择商店目前销售商品类型，或者您建站完成之后的计划销售商品类型。"
      :option="CommodityTypeList"
      :isTop="true"
    />
    <div class="lable">是否在木瓜进行过广告业务<i>*</i></div>
    <PaSelect
      class="myInputType"
      v-model="from.isAdvBertisementBusiness"
      :is_required="true"
      :submitType="submitType"
      successContent="选择商店目前的单日营业额，或者您建站完成之后的计划单日营业额。"
      :option="isPastList"
    />
    <div class="lable" v-if="from.isAdvBertisementBusiness.value == 'true'">
      曾在木瓜使用的广告账户<i>*</i>
    </div>
    <PaInput
      v-if="from.isAdvBertisementBusiness.value == 'true'"
      class="myInputType"
      v-model="from.isAdvBertisementAccount"
      placeholder="请输入曾开通的广告账户*"
      error_content="请输入广告账户"
      is_required_text="请输入广告账户"
      :submitType="submitType"
      :is_required="from.isAdvBertisementBusiness.value == 'true'"
    />
    <div class="auxiliary">
      <div class="previous" @click="returnPage">
        <i class="el-icon-arrow-left"></i>
        <span>返回</span>
      </div>
      <div class="nextButten" @click="next">下一步</div>
    </div>
  </div>
</template>
<script>
import Pacode from "@/components/basic/Pacode.vue";
import PaInput from "@/components/basic/PaInput.vue";
import PaSelect from "@/components/basic/PaSelect.vue";
import PaMCSelect from "@/components/basic/PaMCSelect.vue";
import { province } from "@/assets/js/cityJson.js";
import store from "@/store/index";
import { mapState, mapMutations } from "vuex";
import { register } from "@/assets/js/api";
import { handleResponseErrors } from "@/assets/js/utils";
export default {
  store,
  created() {
    this.province = province.map(item => {
      return {
        value: item.id,
        label: item.name,
        city: item.city.map(items => {
          return {
            value: items.id,
            label: items.name
          };
        })
      };
    });
    let ERFrom = JSON.parse(sessionStorage.getItem("fpp-ERFrom")) || {};
    Object.keys(this.from).forEach(key => {
      let objCople = Array.isArray(this.from[key].value) ? [] : "";
      this.from[key].value = ERFrom[key] || objCople;
    });
  },
  data() {
    return {
      from: {
        Name: { value: "", isError: false }, //企业名
        province: { value: "", isError: false }, //省
        city: { value: "", isError: false }, //市
        DetailedAddress: { value: "", isError: false }, //详细地址
        isPast: { value: "", isError: false }, //是否从事过跨境电商业务
        DailyTurnover: { value: "", isError: false }, //单日营业额
        CommodityType: { value: [], isError: false }, //商品类型
        isAdvBertisementBusiness: { value: "", isError: false }, //是否在木瓜进行过广告业务*
        isAdvBertisementAccount: { value: "", isError: false } //曾在木瓜使用的广告账户
      },
      submitType: false,
      province: [],
      isPastList: [
        {
          label: "是",
          value: "true"
        },
        {
          label: "否",
          value: "false"
        }
      ],
      DailyTurnoverList: [
        {
          label: "1w美金及以下",
          value: "1"
        },
        {
          label: "10w美金及以下",
          value: "2"
        },
        {
          label: "20w美金及以下",
          value: "3"
        },
        {
          label: "30w美金及以下",
          value: "4"
        },
        {
          label: "40w美金及以下",
          value: "5"
        },
        {
          label: "40w美金及以上",
          value: "6"
        }
      ],
      CommodityTypeList: [
        {
          label: "服饰箱包"
        },
        {
          label: "宠物用品"
        },
        {
          label: "电子产品及配件"
        },
        {
          label: "首饰"
        },
        {
          label: "家居用品"
        },
        {
          label: "综合品类"
        }
      ]
    };
  },
  components: { PaInput, Pacode, PaSelect, PaMCSelect },
  computed: {
    city: function() {
      for (let data of this.province) {
        if (data.value == this.from.province.value) {
          return data.city;
        }
      }
    },
    ...mapState(["ERFrom"])
  },
  methods: {
    ...mapMutations(["upData_ERFrom"]),
    returnPage() {
      let form =
        this.ERFrom || JSON.parse(sessionStorage.getItem("fpp-ERFrom"));
      Object.keys(this.from).forEach(key => {
        form[key] = this.from[key].value;
      });
      this.upData_ERFrom(form);
      this.$router.go(-1);
    },
    async sendForm() {
      let seller_id = localStorage.getItem("seller_id");
      let secret_key = localStorage.getItem("secret_key");
      let provinceData = this.province.find(
        item => this.from.province.value == item.value
      );
      let address =
        provinceData.label +
        "," +
        provinceData.city.find(item => this.from.city.value == item.value)
          .label;
      let data = {
        seller_id,
        secret_key,
        username: this.from.Name.value,
        address,
        detail_address: this.from.DetailedAddress.value,
        cross_board_shop_history: this.from.isPast.value == "true",
        daily_revenue: this.from.DailyTurnover.value,
        product_types: this.from.CommodityType.value.join(),
        have_ppy_ad_account: this.from.isAdvBertisementBusiness.value == "true",
        ppy_ad_account:
          this.from.isAdvBertisementBusiness.value == "true"
            ? this.from.isAdvBertisementAccount.value
            : "",
        seller_type: this.$route.path.match(/enterprise/) ? 2 : 1 //1个人 2企业
      };
      let ref = await register.sendStep2(data);
      if (ref.code != 0) {
        handleResponseErrors(ref.code, ref.msg);
        throw new Error("auth_failed");
        return;
      }
    },
    next() {
      window.gtag("event", "Click-button-e2", {
        event_category: "register",
        event_label: "enterprise"
      });
      this.submitType = true;
      this.$nextTick(async () => {
        let query = {};
        if (this.$route.query) {
          query = {
            email: this.$route.query.email,
            business: this.$route.query.business,
            channel: this.$route.query.channel
          };
        }
        try {
          if (
            Object.values(this.from).every(item => {
              return item.isError != true;
            })
          ) {
            let form =
              this.ERFrom || JSON.parse(sessionStorage.getItem("fpp-ERFrom"));
            Object.keys(this.from).forEach(key => {
              form[key] = this.from[key].value;
            });
            this.upData_ERFrom(form);
            await this.sendForm();
            window.gtag("event", "Click-button-e2-success", {
              event_category: "register",
              event_label: "enterprise"
            });
            this.$router.push({
              path: "/register/enterprise/Step3",
              query
            });
          }
        } catch (error) {
          if (error.toString().match(/auth_failed/)) {
            return;
          }
          console.log(error);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.lable {
  height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: $black;
  line-height: 28px;
  i {
    color: red;
    font-style: normal;
  }
}
.myInputType {
  width: 570px;
  margin-top: 20px;
}
.mySelectType {
  display: flex;
  justify-content: space-between;
  .myInputType {
    width: 257px;
  }
}
.auxiliary {
  height: 122px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 70px;
  .previous {
    font-size: 18px;
    font-weight: 500;
    color: $green;
    line-height: 25px;
    cursor: pointer;
  }
  .nextButten {
    width: 160px;
    height: 52px;
    background: $green;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;
    color: $fff;
    line-height: 52px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
